import Hamburguer from '../Atoms/Buttons/Hamburguer/Hamburguer'
import Link from '../Atoms/Link/Link'

import { useEffect, useState } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export interface HeaderProps {
  selected?: string
  onClick: (section: string) => void
  logo: string
  className?: string
}

const Header = ({ selected, onClick, logo, className }: HeaderProps) => {
  const { t } = useTranslation('global')
  const [isOpen, setIsOpen] = useState(false)
  const navClassname = classNames('bg-white', 'p-4', {
    'flex flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-center md:flex-row':
      isOpen,
    'hidden md:flex md:flex-row md:justify-center flex-col md:align-center':
      !isOpen,
  })
  const linkClassname = classNames({
    'ml-7': !isOpen,
  })

  const closeMenu = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        isOpen &&
        !event.target.closest('.nav-container') &&
        !event.target.closest('.hamburger-button')
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])

  const handleClick = (section: string) => {
    onClick(section)
    closeMenu()
  }
  return (
    <div className={className}>
      <div className="antialiased bg-white dark-mode:bg-white">
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-white nav-container">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <img
                alt="logo"
                src={`${process.env.REACT_APP_URL_S3}/images/${logo}`}
                width={62}
                height={62}
              />
              <Hamburguer
                className="md:hidden focus:outline-none focus:shadow-outline"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
            <nav className={navClassname}>
              <Link
                selected={selected === 'howItWorks'}
                onClick={() => handleClick('howItWorks')}
              >
                {t('header.howItWorks')}
              </Link>
              <Link
                selected={selected === 'prizes'}
                className={linkClassname}
                onClick={() => handleClick('prizes')}
              >
                {t('header.prizes')}
              </Link>
              <Link
                selected={selected === 'ranking'}
                className={linkClassname}
                onClick={() => handleClick('ranking')}
              >
                {t('header.ranking')}
              </Link>
              <Link
                selected={selected === 'contact'}
                className={linkClassname}
                onClick={() => handleClick('contact')}
              >
                {t('header.contact')}
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
